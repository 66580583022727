export const SalesNav = [
  {
    icon: "pe-7s-cash",
    label: "Vendas",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Clientes",
            to: '/#/vendas/clientes'
          },
          {
            label: "Produtos",
            to: '/#/vendas/produtos'
          },
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Contratos",
            to: "/#/vendas/contratos"
          },
          {
            label: "Ficha de Serviço",
            to: "/#/vendas/ficha-servico"
          },
          {
            label: "Faturas",
            to: "/#/vendas/faturas"
          },
        ],
      }
    ]
  }
];
export const PurchasesNav = [
  {
    icon: "pe-7s-shopbag",
    label: "Compras",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Fornecedores",
            to: '/#/compras/fornecedores'
          },
          {
            label: "Impostos",
            to: '/#/compras/impostos'
          },
        ]
      },
      {
        label: "Controle",
        content: []
      }
    ]
  }
];
export const FinancialNav = [
  {
    icon: "pe-7s-piggy",
    label: "Financeiro",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Categorias",
            to: '/#/financeiro/categorias'
          },
          {
            label: "Contas",
            to: '/#/financeiro/contas'
          },
          {
            label: "Contas a Receber",
            to: '/#/financeiro/contas-receber'
          },
          {
            label: "Contas a Pagar",
            to: '/#/financeiro/contas-pagar'
          },
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Conciliação Bancária",
            to: '/#/financeiro/conciliacao'
          },
          {
            label: "Extrato",
            to: '/#/financeiro/extrato'
          },
          {
            label: "Remessas",
            to: '/#/financeiro/remessas'
          },
          {
            label: "Retornos",
            to: '/#/financeiro/retornos'
          },
          {
            label: "Histórico Movimentações",
            to: '/#/financeiro/historico'
          },
        ]
      }
    ]
  }
];
export const ReportsNav = [
  {
    icon: "pe-7s-graph1",
    label: "Relatórios",
    content: [

      {
        label: "Financeiro",
        content: [
          {
            label: "Fluxo Diário",
            to: '/#/relatorios/fluxo-diario'
          },
          {
            label: "Fluxo Mensal",
            to: '/#/relatorios/fluxo-mensal'
          },
          {
            label: "Análise Recebimentos",
            to: '/#/relatorios/analise-recebimentos'
          },
          {
            label: "Análise Pagamentos",
            to: '/#/relatorios/analise-pagamentos'
          },
          {
            label: "Posição Contas",
            to: '/#/relatorios/posicao-contas'
          },
          {
            label: "DRE",
            to: '/#/relatorios/dre'
          },
        ]
      },
      {
        label: "Vendas",
        content: [
          {
            label: "Mais Vendidos",
            to: '/#/relatorios/mais-vendidos'
          },
        ]
      },
      {
        label: "Geral",
        content: [
          {
            label: "Produtos Cliente",
            to: '/#/relatorios/produtos-cliente'
          },
          {
            label: "Clientes",
            to: '/#/relatorios/clientes'
          },
        ]
      },
      {
        label: "",
        content: []
      }
    ]
  }
];
