import React, { Fragment, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { FaTicketAlt, FaHeadset, FaRegUser, FaRegLightbulb } from "react-icons/fa";

import { getInfoUser, logout } from "../../../services/auth";

const UserBox = ({ history, setLoading, setIsAuthenticated, location }) => {
  const user = getInfoUser();
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const handleToggleDropDown = () => {
    setToggleDropDown(!toggleDropDown);
  }
  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-3 header-user-info">
              <div className="widget-heading">{user.name}</div>
              <div className="widget-subheading">{user.email}</div>
            </div>
            <div className="widget-content-left">
              <UncontrolledButtonDropdown id="btnUserBox" toggle={handleToggleDropDown} isOpen={toggleDropDown}>
                <DropdownToggle onClick={handleToggleDropDown} className="p-0 ml-2 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle" style={{height: 44, width: 44}}>
                    <div className="icon-wrapper-bg bg-primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                    {
                      user.company.logo_url &&
                      <img src={user.company.logo_url} style={{maxWidth: 44, maxHeight: 44, margin: '0 auto'}} alt="Logo da Empresa" />
                    }
                    {
                      !user.company.logo_url &&
                      <FaRegUser style={{margin: "0 auto"}} color="#545cd8" fontSize="20px" />
                    }
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-primary">
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {user.company !== undefined && <>{user.company.name}</>}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button id="logout" className="btn-pill btn-shadow btn-shine" color="focus" onClick={async () => {
                                setLoading(true);
                                await logout();
                                setLoading(false);
                              }}>
                                Sair
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-xs"
                    style={{
                      height: "auto",
                    }}>
                    <PerfectScrollbar>
                      <Nav onClick={handleToggleDropDown} vertical>
                        <NavItem>
                          <NavLink href="/#/perfil">
                            Meus Dados
                          </NavLink>
                        </NavItem>
                        {
                          user.permissions.includes('users') && location.pathname !== "/bloqueado" &&
                          <NavItem>
                            <NavLink href="/#/usuarios">
                              Meus Usuários
                            </NavLink>
                          </NavItem>
                        }
                        {
                          user.permissions.includes('company_profile') && location.pathname !== "/bloqueado" &&
                            <NavItem>
                              <NavLink href="/#/dados-empresa">
                                Dados da Empresa
                              </NavLink>
                            </NavItem>
                        }
                        {
                          user.permissions.includes('company_settings') && location.pathname !== "/bloqueado" &&
                          <NavItem>
                            <NavLink href="/#/config-empresa">
                              Configurações da Empresa
                            </NavLink>
                          </NavItem>
                        }
                        {
                          (user.permissions.includes('installments_receive') || user.permissions.includes('installments_pay')) && location.pathname !== "/bloqueado" &&
                          <NavItem>
                            <NavLink href="/#/importa-dados">
                              Importar Dados
                            </NavLink>
                          </NavItem>
                        }
                        {
                          user.permissions.includes('tools') && location.pathname !== "/bloqueado" &&
                          <NavItem>
                            <NavLink href="/#/documentos-fiscais">
                              Documentos Fiscais
                              </NavLink>
                          </NavItem>
                        }
                        {
                          user.permissions.includes('ged') && location.pathname !== "/bloqueado" &&
                          <NavItem>
                            <NavLink href="/#/ged">
                              Gerenciamento eletrônico de documentos (G.E.D)
                            </NavLink>
                          </NavItem>
                        }
                        {
                          user.level === 1 && location.pathname !== "/bloqueado" &&
                          <>
                            <NavItem>
                              <NavLink href="/#/logs">
                                Logs do Sistema
                              </NavLink>
                            </NavItem>
                          </>
                        }
                        </Nav>
                    </PerfectScrollbar>
                  </div>
                  {location.pathname !== "/bloqueado" && <><Nav vertical>
                    <NavItem className="nav-item-divider mb-0" />
                  </Nav>
                  <div className="grid-menu">
                    <Row onClick={handleToggleDropDown} className="no-gutters">
                    {
                        user.type !== 2 &&
                        <Col>
                          <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                            outline color="primary" onClick={() => history.push('/acesso-suporte')}>
                              <FaHeadset size="2rem" />
                              <Col md="12">
                                <b>Suporte</b>
                              </Col>
                          </Button>
                        </Col>
                      }
                      <Col>
                        <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="info" onClick={() => history.push('/sugestoes')}>
                          <FaRegLightbulb size="2rem" />
                          <Col md="12">
                            <b>Sugestões</b>
                          </Col>
                        </Button>
                      </Col>
                      <Col>
                        <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="success" onClick={() => history.push('/tickets')}>
                          <FaTicketAlt size="2rem" />
                          <Col md="12">
                            <b>Tickets</b>
                          </Col>
                        </Button>
                      </Col>
                    </Row>
                  </div></>}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserBox;
