import React, { Fragment, useEffect, useState } from "react";

import { Col, Row, Button, Form, FormGroup, Input, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../Layout/AppMain/Components/Toast";
import AuthenticationSliders from "../../../Layout/AppMain/Components/AuthenticationSliders";
import api from "../../../services/api";

const ForgotPassword = ({ setLoading, history, isAuthenticated }) => {
  const [ email, setEmail ] = useState('')
  const [ validation, setValidation ] = useState(false)

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  const handleSubmit = async () => {
    if (!email || !email.trim()) {
      setValidation(true)
      return false
    }

    setValidation(false)
    setLoading(true)
    try {
      await api.post('forgot', { email })
      SuccessToast({ placeholder: 'Solicitação realizada com sucesso! Por favor, verifique sua caixa de entrada (Caso não encontre, verifique também o spam).' })
      setEmail('')
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar solicitar a redifinição de senha, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated])

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <AuthenticationSliders />
          <Col lg="8" md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="6" md="8" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                <div>Esqueceu sua senha?</div>
                <span>Preencha o seu e-mail abaixo que iremos enviar um link para que você possa redefinir sua senha!</span>
              </h4>
              <div>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Input invalid={validation} type="email" placeholder="Digite seu e-mail aqui..." value={email} onChange={({target: { value }}) => setEmail(value)} onKeyUp={handleKeyUp} />
                      <FormFeedback>Informe um e-mail válido</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4 d-flex align-items-center">
                  <h6 className="mb-0">
                    <Link className="text-primary" to="/login">
                      Entrar com uma conta existente
                    </Link>
                  </h6>
                  <div className="ml-auto">
                    <Button type="button" id="forgot_password" color="primary" size="lg" onClick={handleSubmit}>
                      Recuperar Senha
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default ForgotPassword;
