import Slider from "react-slick";

import fugapoints from "../../../../assets/utils/images/authentication/FugaPoints.webp";
import bg1 from "../../../../assets/utils/images/authentication/bg1.webp";
import bg2 from "../../../../assets/utils/images/authentication/bg2.webp";
import bg3 from "../../../../assets/utils/images/authentication/bg3.webp";

import { Col } from "reactstrap";

const AuthenticationSliders = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };

  return (
    <>
      <Col lg="4" className="d-none d-lg-block">
        <div className="slider-light">
          <Slider {...settings}>
            <div className="h-100 d-flex justify-content-center align-items-center">
              <a href="https://indique.buzz/fuganholi" target="_blank" rel="noopener noreferrer">
                <div className="slide-img-bg slide-img"
                  style={{
                    backgroundImage: "url(" + fugapoints + ")",
                  }}
                >
                </div>
              </a>
            </div>
            <div className="h-100 d-flex justify-content-center align-items-center bg-woman">
              <div className="slide-img-bg"
                style={{
                  backgroundImage: "url(" + bg1 + ")",
                }}/>
              <div className="slider-content">
                <h3>G.E.D</h3>
                <p style={{fontSize: "24px"}}>
                  Envie e receba arquivos de seus clientes,
                  gerenciando o histórico e armazenando os documentos pelo Fuganholi Contábil
                </p>
              </div>
            </div>
            <div className="h-100 d-flex justify-content-center align-items-center bg-man">
              <div className="slide-img-bg"
                style={{
                  backgroundImage: "url(" + bg3 + ")",
                }}/>
              <div className="slider-content">
                <h3>Download de Notas Fiscais</h3>
                <p style={{fontSize: "24px"}}>
                  Tenha facilidade ao acessar XMLs e PDFs das notas de seus clientes
                  que utilizam um emissor da Fuganholi Sistemas
                </p>
              </div>
            </div>
            <div className="h-100 d-flex justify-content-center align-items-center bg-woman2">
              <div className="slide-img-bg opacity-6"
                style={{
                  backgroundImage: "url(" + bg2 + ")",
                }}/>
              <div className="slider-content">
                <h3>Faturas</h3>
                <p style={{fontSize: "24px"}}>
                  Gerencie os contratos e ficha de serviços de seus clientes,
                  e ainda emita fatura e boleto para realizar as cobranças
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </Col>
    </>
  )
}

export default AuthenticationSliders;
