import React, { useEffect, useState } from "react";

import { Nav } from "reactstrap";
import NavItemMega from "./Components/NavItemMega";
import validMenu from "../../../../services/validMenu";
import api from "../../../../services/api";

import {
  SalesNav,
  PurchasesNav,
  FinancialNav,
  ReportsNav
} from "../../../AppNav/NavItems";


const MegaMenu = ({location}) => {
  const [menuSales, setMenuSales] = useState({});
  const [menuPurchases, setMenuPurchases] = useState({});
  const [menuFinancial, setMenuFinancial] = useState({});
  const [menuReports, setMenuReports] = useState({});

  useEffect(() => {
    const loadMenu = async () => {
      let userPermissions = await api.get('users/me/permissions');
      userPermissions = userPermissions.data.permissions;

      let menu = validMenu(SalesNav[0], userPermissions);
      if (menu.content.length > 0) {
        setMenuSales({...menu});
      }


      menu = validMenu(PurchasesNav[0], userPermissions);
      if (menu.content.length > 0) {
        setMenuPurchases({...menu});
      }

      menu = validMenu(FinancialNav[0], userPermissions);
      if (menu.content.length > 0) {
        setMenuFinancial({...menu});
      }

      menu = validMenu(ReportsNav[0], userPermissions);
      if (menu.content.length > 0) {
        setMenuReports({...menu});
      }
    }

    loadMenu();
  }, []);
  return (
    location.pathname !== "/bloqueado" && <>
      <Nav className="header-megamenu">
        {Object.keys(menuSales).length > 0 &&
          <NavItemMega data={menuSales} />
        }
        {Object.keys(menuPurchases).length > 0 &&
          <NavItemMega data={menuPurchases} />
        }
        {Object.keys(menuFinancial).length > 0 &&
          <NavItemMega data={menuFinancial} />
        }
        {Object.keys(menuReports).length > 0 &&
          <NavItemMega data={menuReports} />
        }
      </Nav>
    </>
  );
}

export default MegaMenu;
