const menuPermissions = () => {
  return {
    '/#/vendas/clientes': 'customers',
    '/#/vendas/produtos': 'products',
    '/#/vendas/contratos': 'contracts',
    '/#/vendas/ficha-servico': 'services',
    '/#/vendas/faturas': 'bills',
    '/#/compras/fornecedores': 'providers',
    '/#/compras/impostos': 'taxes',
    '/#/financeiro/categorias': 'categories',
    '/#/financeiro/contas': 'accounts',
    '/#/financeiro/contas-receber': 'installments_receive',
    '/#/financeiro/contas-pagar': 'installments_pay',
    '/#/financeiro/extrato': 'extract',
    '/#/financeiro/conciliacao': 'compensates',
    '/#/financeiro/remessas': 'installments_receive',
    '/#/financeiro/retornos': 'installments_receive',
    '/#/financeiro/historico' : 'installments_historic',
    '/#/relatorios/fluxo-diario': 'daily_flow',
    '/#/relatorios/fluxo-mensal': 'monthly_flow',
    '/#/relatorios/mais-vendidos': 'best_products',
    '/#/relatorios/produtos-cliente': 'customers_products',
    '/#/relatorios/analise-recebimentos': 'received',
    '/#/relatorios/analise-pagamentos': 'payed',
    '/#/relatorios/clientes': 'report_customers',
    '/#/relatorios/posicao-contas': 'position_persons',
    '/#/relatorios/dre': 'dre',
    '/#/config-empresa': 'company_settings',
    '/#/dados-empresa': 'company_profile',
    '/#/usuarios': 'users',
    '/#/usuarios/cadastrar': 'users',
    '/#/usuarios/documentos-fiscais': 'tools',
    '/#/usuarios/ged': 'ged',
  }
}

export default menuPermissions;

