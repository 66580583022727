const map = {
  vendas: {
    0: 'Acessou tela de vendas (Vendas > Controle > Vendas e Orçamentos)',
    clientes: 'Acessou a tela de clientes (Vendas > Cadastros > Clientes)',
    produtos: 'Acessou a tela de produtos (Vendas > Cadastros > Produtos)',
    contratos: 'Acessou a tela de contratos (Vendas > Controle > Contratos)',
  },
  compras: {
    0: 'Acessou tela de compras (Compras > Controle > Compras)',
    fornecedores: 'Acessou a tela de fornecedores (Compras > Cadastros > Fornecedores)',
    impostos: 'Acessou a tela de impostos (Compras > Cadastros > Impostos)',
  },
  financeiro: {
    categorias: 'Acessou a tela de categorias (Financeiro > Cadastros > Categorias)',
    contas: 'Acessou a tela de contas bancárias (Financeiro > Cadastros > Contas)',
    'contas-receber': 'Acessou a tela de contas a receber (Financeiro > Cadastros > Contas a Receber)',
    'contas-pagar': 'Acessou a tela de contas a pagar (Financeiro > Cadastros > Contas a Pagar)',
    conciliacao: 'Acessou a tela de conciliação bancária (Financeiro > Controle > Conciliação Bancária)',
    extrato: 'Acessou a tela de extrato bancário (Financeiro > Controle > Extrato)',
    remessas: 'Acessou a tela de remessas bancárias (Financeiro > Controle > Remessas)',
    retornos: 'Acessou a tela de retornos bancários (Financeiro > Controle > Retornos)',
    historico: 'Acessou a tela de histórico de movimentações (Financeiro > Controle > Histórico Movimentações)',
  },
  relatorios: {
    'fluxo-diario': 'Acessou relatório de fluxo diário (Relatórios > Financeiro > Fluxo Diário)',
    'fluxo-mensal': 'Acessou relatório de fluxo mensal (Relatórios > Financeiro > Fluxo Mensal)',
    'analise-recebimentos': 'Acessou relatório de análise de recebimentos (Relatórios > Financeiro > Análise de Recebimentos)',
    'analise-pagamentos': 'Acessou relatório de análise de pagamentos (Relatórios > Financeiro > Análise de Pagamentos)',
    'posicao-contas': 'Acessou relatório de posição de contas (Relatórios > Financeiro > Posição Contas)',
    dre: 'Acessou relatório de dre gerencial (Relatórios > Financeiro > DRE)',
    'vendas-cliente': 'Acessou relatório de vendas por clientes (Relatórios > Vendas > Vendas Cliente)',
    'mais-vendidos': 'Acessou relatório de produtos mais vendidos (Relatórios > Vendas > Mais Vendidos)',
    'produtos-cliente': 'Acessou relatório de produtos por clientes (Relatórios > Geral > Produtos Cliente)',
    clientes: 'Acessou relatório de clientes (Relatórios > Geral > Clientes)',
  },
  perfil: {
    0: 'Acessou a tela de perfil (Meus Dados)'
  },
  usuarios: {
    0: 'Acessou a tela de usuários (Meus Usuários)'
  },
  'dados-empresa': {
    0: 'Acessou a tela de dados da empresa (Dados da Empresa)'
  },
  'config-empresa': {
    0: 'Acessou a tela de configurações da empresa (Configurações da Empresa)'
  },
  'acesso-suporte': {
    0: 'Acessou a tela de acesso suporte (Icone Suporte)'
  },
  sugestoes: {
    0: 'Acessou a tela de sugestões (Icone Sugestões)'
  },
  tickets: {
    0: 'Acessou a tela de tickets (Icone Tickets)'
  },
  logs: {
    0: 'Acessou a tela de logs do sistema (Logs do Sistema)'
  },
  'documentos-fiscais': {
    0: 'Acessou a tela de impressão de documentos fiscais (Documentos Fiscais)'
  },
  ged: {
    0: 'Acessou a tela de gerenciamento eletrônico de documentos G.E.D (Gerenciamento eletrônico de documentos (G.E.D))'
  }
}

export default map
