import menuPermissions from './menuPermissions';

const validMenu = (originalMenu, userPermissions) => {
  const MenuPermissions = menuPermissions();

  let menu = {
    icon: originalMenu.icon,
    label: originalMenu.label,
    content: []
  }
  menu.content = originalMenu.content.map(sales => {
    let subMenu = {
      label: sales.label,
      content: []
    }

    subMenu.content = sales.content.filter(sub => {
      return userPermissions.includes(MenuPermissions[sub.to]);
    });


    return subMenu;
  });

  menu.content = menu.content.filter(sales => {
    return sales.content.length > 0;
  });

  return menu;
}

export default validMenu;
