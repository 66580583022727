import React, { Fragment, useEffect, useState } from "react";

import { Col, Row, Button, Form, FormGroup, Input, FormFeedback, Label, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { Link } from "react-router-dom";
import { RiEyeCloseLine } from "react-icons/ri";

import api from "../../../services/api";
import { ImEye } from "react-icons/im";
import PasswordForce from "../../../Layout/AppMain/Components/PasswordForce";
import { ErrorToast, SuccessToast } from "../../../Layout/AppMain/Components/Toast";
import AuthenticationSliders from "../../../Layout/AppMain/Components/AuthenticationSliders";
import { formatPassword } from 'nfutils';

const RecoveryPassword = ({ setLoading, match, history, isAuthenticated }) => {
  const { user_id, token } = match.params
  const [levelPassword, setLevelPassword] = useState(0);
  const [showPasswordForce, setShowPasswordForce] = useState(false);
  const [ formData, setFormData ] = useState({
    password: '',
    confirm_password: ''
  })
  const [ validation, setValidation ] = useState({
    password: false,
    confirm_password: false
  })
  const [ viewPassword, setViewPassword ] = useState(false)
  const [ viewConfirmPassword, setViewConfirmPassword ] = useState(false)

  const toggleViewPassword = () => setViewPassword(!viewPassword)
  const toggleViewConfirmPassword = () => setViewConfirmPassword(!viewConfirmPassword)

  const handleSubmit = async () => {
    let invalid = {}
    if (!formData.password || !formData.password.trim() || levelPassword < 6) {
      invalid.password = true
    }
    if (formData.confirm_password !== formData.password) {
      invalid.confirm_password = true
    }

    if (Object.keys(invalid).length > 0) {
      setValidation(invalid)
      return false
    }

    setValidation(false)
    setLoading(true)
    try {
      await api.post('forgot/recovery', {
        ...formData,
        user_id,
        token
      })
      setLoading(false)
      SuccessToast({ placeholder: 'Senha alterada com sucesso' })
      history.push('/login')
    } catch (e) {
      console.log(e)
      setLoading(false)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar solicitar a redifinição de senha, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    } else {
      setLoading(true)
      api.post('forgot/valid', { user_id, token })
      .then(response => {
        setLoading(false)
        if (response.status !== 200) {
          let msg = response.data.errors ? response.data.errors.join('<br />') : 'Ocorreu um erro interno ao tentar validar o link, tente novamente mais tarde'
          ErrorToast({ placeholder: msg })
          history.push('/login')
        }
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar validar o link, tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
        history.push('/login')
      })
    }
  }, [isAuthenticated, user_id, token])

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <AuthenticationSliders />
          <Col lg="8" md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="6" md="8" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                <div>Recuperação de Conta</div>
                <span>Preencha os campo abaixo para alterar a senha de seu usuário</span>
              </h4>
              <div>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Nova Senha</Label>
                      <InputGroup>
                        <Input id="password" invalid={validation.password} type={viewPassword ? 'text' : 'password'} onChange={({ target: { value } }) => setFormData({...formData, password: formatPassword(value)})} placeholder="Digite sua nova senha aqui..." value={formData.password} onBlur={() => setShowPasswordForce(false)} onFocus={() => setShowPasswordForce(true)} />
                        <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewPassword}>
                            <InputGroupText>
                              <RiEyeCloseLine hidden={viewPassword}/>
                              <ImEye hidden={!viewPassword} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>Informe uma senha válida</FormFeedback>
                      </InputGroup>
                      {
                        showPasswordForce &&
                        <>
                          <br />
                          <PasswordForce password={formData.password} onLevel={value => setLevelPassword(value)} />
                        </>
                      }
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Confirme a Nova Senha</Label>
                      <InputGroup>
                        <Input id="confirm_password" invalid={validation.confirm_password} type={viewConfirmPassword ? 'text' : 'password'} onChange={({ target: { value } }) => setFormData({...formData, confirm_password: formatPassword(value)})} placeholder="Repita sua nova senha aqui..." value={formData.confirm_password} onKeyDown={e => e.key === 'Enter' ? handleSubmit() : false} />
                        <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewConfirmPassword}>
                            <InputGroupText>
                              <RiEyeCloseLine hidden={viewConfirmPassword}/>
                              <ImEye hidden={!viewConfirmPassword} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>A confirmação de senha deve ser igual a senha digitada no campo anterior</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4 d-flex align-items-center">
                  <h6 className="mb-0">
                    <Link className="text-primary" to="/login">
                      Entrar com uma conta existente
                    </Link>
                  </h6>
                  <div className="ml-auto">
                    <Button type="button" color="primary" size="lg" onClick={handleSubmit}>
                      Salvar Senha
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
export default RecoveryPassword;
