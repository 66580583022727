import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

const Index = lazy(() => import("./Index/"));
const Form = lazy(() => import("./Form/"));
const Manager = lazy(() => import("./Manager/"));

const ImportData = (props) => {
  return (
    <Suspense>
      <Switch>
        <Route
          path={`${props.match.url}/gerenciar`}
          render={({ match }) => <Manager {...props} match={match} />}
        />
        <Route
          path={`${props.match.url}/:entity`}
          render={({ match }) => <Form {...props} match={match} />}
        />
        <Route
          exact
          path={`${props.match.url}/`}
          render={({ match }) => <Index {...props} match={match} />}
        />
      </Switch>
    </Suspense>
  );
};

export default ImportData;
