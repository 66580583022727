import { Route, Switch, Redirect } from "react-router-dom";
import React, { Suspense, lazy, useEffect } from "react";
import Loader from "react-loaders";
import cx from "classnames";

import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";

import '../../assets/layout/css/style.css';
import { getInfoUser } from '../../services/auth';
import api from '../../services/api';
import mapRoutes from '../../services/mapRoutes';
import menuPermissions from "../../services/menuPermissions";
import { ErrorToast } from "./Components/Toast";
import ImportData from "../../Pages/ImportData";

const Dashboard = lazy(() => import("../../Pages/Dashboard"));
const Product = lazy(() => import("../../Pages/Product"));
const Account =  lazy(() =>  import("../../Pages/Account"));
const Installment = lazy(() => import("../../Pages/Installment"));
const Users =  lazy(() =>  import("../../Pages/User"));
const Profile =  lazy(() =>  import("../../Pages/Profile"));
const Company = lazy(() => import("../../Pages/Company"));
const CompanySetting = lazy(() => import("../../Pages/CompanySetting"));
const AccessSupport = lazy(() => import("../../Pages/AccessSupport"));
const Person = lazy(() => import("../../Pages/Person"));
const Remittance =  lazy(() =>  import("../../Pages/RemittanceBank"));
const Return =  lazy(() =>  import("../../Pages/ReturnBank"));
const NFConta =  lazy(() =>  import("../../Pages/NFConta"));
const Ticket = lazy(() => import("../../Pages/Ticket"));
const Suggestion = lazy(() => import("../../Pages/Suggestion"));
const Category =  lazy(() =>  import("../../Pages/Category"));
const Extract =  lazy(() =>  import("../../Pages/Extract"));
const Historic =  lazy(() =>  import("../../Pages/HistoricLog"));
const DailyFlow =  lazy(() =>  import("../../Pages/DailyFlow"));
const MonthlyFlow =  lazy(() =>  import("../../Pages/MonthlyFlow"));
const BestProduct =  lazy(() =>  import("../../Pages/BestProduct"));
const ProductCustomer =  lazy(() =>  import("../../Pages/ProductCustomer"));
const Compensate = lazy(() => import("../../Pages/Compensate"));
const Received = lazy(() => import("../../Pages/Received"));
const Payed =  lazy(() =>  import("../../Pages/Payed"));
const CustomerList =  lazy(() =>  import("../../Pages/CustomerList"));
const PositionPerson =  lazy(() =>  import("../../Pages/PositionPerson"));
const DRE =  lazy(() =>  import("../../Pages/DRE"));
const Log = lazy(() => import("../../Pages/Log"));
const Contract =  lazy(() =>  import("../../Pages/Contract"));
const ServiceSheet = lazy(() => import("../../Pages/ServiceSheet"));
const Bill = lazy(() => import("../../Pages/Bill"));
const Document = lazy(() => import("../../Pages/Document"));
const GED = lazy(() => import("../../Pages/GED"));

const AppMain = (props) => {
  const accessValidate = (route, user) => {
    let routeAdmin = ['/logs']

    if (user.level === 1) {
      return true;
    } else if (routeAdmin.includes(route)) {
      return false;
    } else if (!menuPermissions()[`/#${route}`]) {
      return true;
    } else if (user.permissions.includes(menuPermissions()[`/#${route}`])) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    let user = getInfoUser();
    props.history.listen((location) => {
      if (accessValidate(location.pathname, user)) {
        let route = location.pathname.split('/')
        if (((route.length === 3 && isNaN(route[2])) || route.length < 3) && mapRoutes[route[1]]) {
          let data = {
            event: 'access',
            additional_details: {}
          }

          if (route.length === 2) {
            data.additional_details['Ação'] = mapRoutes[route[1]][0] || ''
          } else if (mapRoutes[route[1]][route[2]]) {
            data.additional_details['Ação'] = mapRoutes[route[1]][route[2]]
          }

          if (Object.keys(data.additional_details).length > 0) {
            api.post('logs', data)
          }
        }
      } else {
        ErrorToast({ placeholder: 'Você não possui permissão de acesso à essa página! Caso necessite, entre em contato com o administrador do sistema!' })
        props.history.push('/');
      }
    });
  }, []);

  return (
    <>
      <Suspense fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader show={true} type="ball-spin-fade-loader"/>
            </div>
              <h6 className="mt-5">
                Carregando...
              </h6>
          </div>
        </div>
      }>
        <AppHeader {...props} />
        <div className="app-main">
          {props.width <= 991 && <AppSidebar />}
          <div className="app-main__outer">
            <div className={cx(
              "app-main__inner",
              {"col-md-10 offset-md-1": props.width > 991}
            )}>
              <Switch>
                  {/** Aqui deve ser definidas as rotas do sistema */}
                  <Route exact path="/" render={({match}) => <Dashboard {...props} match={match} />
                  } />
                  <Route path="/vendas/contratos" render={({match}) => <Contract {...props} match={match} />
                  } />
                  <Route path="/vendas/produtos" render={({match}) => <Product {...props} match={match} />
                  } />
                  <Route path="/vendas/clientes" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/vendas/ficha-servico" render={({match}) => <ServiceSheet {...props} match={match} />
                  } />
                  <Route path="/vendas/faturas" render={({match}) => <Bill {...props} match={match} />
                  } />
                  <Route path="/compras/fornecedores" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/compras/impostos" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/financeiro/contas" render={({match}) => <Account {...props} match={match} />
                  } />
                  <Route path="/financeiro/contas-receber" render={({match}) => <Installment {...props} match={match} />
                  } />
                  <Route path="/financeiro/contas-pagar" render={({match}) => <Installment {...props} match={match} />
                  } />
                  <Route path="/financeiro/remessas" render={({match}) => <Remittance {...props} match={match} />
                  } />
                  <Route path="/financeiro/retornos" render={({match}) => <Return {...props} match={match} />
                  } />
                  {/* <Route path="/financeiro/nfconta" render={({match}) => <NFConta {...props} match={match} />
                  } /> */}
                  <Route path="/financeiro/categorias" render={({match}) => <Category {...props} match={match} />
                  } />
                  <Route path="/financeiro/extrato" render={({match}) => <Extract {...props} match={match} />
                  } />
                  <Route path="/financeiro/conciliacao" render={({match}) => <Compensate {...props} match={match} />
                  } />
                  <Route path="/financeiro/historico" render={({match}) => <Historic {...props} match={match} />
                  } />
                  <Route path="/relatorios/fluxo-diario" render={({match}) => <DailyFlow {...props} match={match} />
                  } />
                  <Route path="/relatorios/fluxo-mensal" render={({match}) => <MonthlyFlow {...props} match={match} />
                  } />
                  <Route path="/relatorios/mais-vendidos" render={({match}) => <BestProduct {...props} match={match} />
                  } />
                  <Route path="/relatorios/produtos-cliente" render={({match}) => <ProductCustomer {...props} match={match} />
                  } />
                  <Route path="/relatorios/analise-recebimentos" render={({match}) => <Received {...props} match={match} />
                  } />
                  <Route path="/relatorios/analise-pagamentos" render={({match}) => <Payed {...props} match={match} />
                  } />
                  <Route path="/relatorios/clientes" render={({match}) => <CustomerList {...props} match={match} />
                  } />
                  <Route path="/relatorios/posicao-contas" render={({match}) => <PositionPerson {...props} match={match} />
                  } />
                  <Route path="/relatorios/dre" render={({match}) => <DRE {...props} match={match} />
                  } />
                  <Route path="/usuarios" render={({match}) => <Users {...props} match={match} />
                  } />
                  <Route path="/perfil" render={({match}) => <Profile {...props} match={match} />
                  } />
                  <Route path="/dados-empresa" render={({match}) => <Company {...props} match={match} />
                  } />
                  <Route path="/config-empresa" render={({match}) => <CompanySetting {...props} match={match} />
                  } />
                  <Route path="/importa-dados" render={({match}) => <ImportData {...props} match={match} />
                  } />
                  <Route path="/acesso-suporte" render={({match}) => <AccessSupport {...props} match={match} />
                  } />
                  <Route path="/tickets" render={({match}) => <Ticket {...props} match={match} />
                  } />
                  <Route path="/sugestoes" render={({match}) => <Suggestion {...props} match={match} />
                  } />
                  <Route path="/documentos-fiscais" render={({match}) => <Document {...props} match={match} />
                  } />
                  <Route path="/logs" render={({match}) => <Log {...props} match={match} />
                  } />
                  <Route path="/ged" render={({match}) => <GED {...props} match={match} />
                  } />
                  <Route render={() => <Redirect to="/" />} />
              </Switch>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  )
};

export default AppMain;
