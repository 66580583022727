import { useEffect } from "react"
import { Spinner } from "reactstrap"
import './thanks.css'

const Thanks = ({ history }) => {

  useEffect(() => {

    window.fbq('track', 'Lead');

    setTimeout(() => {
          window.location.assign('/')
    }, 10000)
  }, [])

  return (
    <>
      <div className="thanks-app-header">
        <div className="thanks-app-header-background">
          <div className="thanks-app-header-retangle"></div>
          <div className="thanks-app-header-retangle-2"></div>
        </div>
         <div className="thanks-app-header-content">
          <nav className="navbar navbar-expand-lg mb-3">
      <img id="navLogo" src="https://fuganholisistemas.com.br/img/fuganholi-branco.svg" alt="Logo Fuganholi Sistemas" />

      </nav>
          <div className="row text-center justify-content-center">
                <div className="col-md-10 padding-mobile">
                    <br/>
                   <div className="col-md-12 col-sm-12 title-page mt-5">
                        <h1 className="font-weight-bold">Obrigado!</h1>
                        </div>
                        <div className="col-md-12 col-sm-12 mt-5">
                        <h4 className="text-center">
                            Estamos configurando seu acesso, em breve você será redirecionado para o sistema.
                        </h4>
                        <div className="col-md-12 mt-2 d-flex justify-content-center mt-5">
                            <Spinner style={{
                                height: '3rem',
                                width: '3rem'
                              }}/>
                        </div>
                        </div>
                    <div className="row inMobile w-100" style={{minHeight: '400px', position: 'relative'}}>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Thanks
